.letterhead{
  display:block;
  margin:0;
  padding:0;
  width:100%;
  height:7px;
}
.letterhead__left{
  width:100%;
  height:7px;
  background-color:#e81912
}
.nav-menu{
  padding-left:0;
  margin-right:1rem;
}
.nav-menu .icon-ctrl{
  transform:rotate(180deg);
  display:inline-block
}

@media (min-width:900px) {
  .nav-menu {
      padding-top: 40px;
  }
}

.nav-menu li{
  display:block;
  text-align:left
}
@media (min-width:900px){
  .nav-menu li{
      display:inline-block;
      position:relative;
      text-align:center
  }
}
.nav-menu a:hover{
  text-decoration:none;
}

.nav-menu .sub-menu{
  margin-left:0;
  padding-left:0;
  margin-bottom:15px;
  display:none;
}

@media (min-width:900px){
  .nav-menu .sub-menu{
      background-color:#fff;
      padding:0;
      position:absolute;
      left:-2px;
      z-index:99999;
      height:1px;
      width:1px;
      overflow:hidden;
      clip:rect(1px,1px,1px,1px);
      margin-bottom:0;
      display:block;
      box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  }
}

.nav-menu .sub-menu a {
  display:block;
  color:#000;
  margin:0;
  width:100%;
  font-size:16px;
  font-weight:300;
  border-bottom:1px solid #aeaab1;
  padding:9px 12px 9px 20px;
  text-decoration: none;
}
@media (min-width:900px){
  .nav-menu .sub-menu a{
      width:200px;
      padding-left:12px
  }
}

.nav-menu .open>ul,.nav-menu li:hover>ul,.nav-menu ul li:hover>ul{
  clip:inherit;
  overflow:inherit;
  height:inherit;
  width:inherit;
  display:block
}

.nav-menu li:hover, .nav-menu li:focus>a,.nav-menu li:hover>a,.nav-menu li a:focus,.nav-menu li a:hover{
  background:hsla(0,0%,100%,.2);
  color:#dc212a
}
.nav-menu ul a:focus,.nav-menu ul a:hover,.nav-menu ul ul a:focus,.nav-menu ul ul a:hover{
  color:#000;
  background-color:#f9eee9;
}
.language-switcher{
  padding-top:50px
}

.logo{
  display:inline-block;
  max-width:75%
}
@media (min-width:900px){
  .logo{
      width:auto;
      display:block
  }
}
